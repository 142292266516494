export default {
  home: {
  conn: 'Home',
  about:'About dragon fruit',
  aboutinfo:`Pitaya was born in the Swiss Crypto Valley and was created by WEBDAO.
  WEBDAO is an application R&D alliance composed of a group of professional blockchain ecological application R&D technology teams from Switzerland, the United States and Singapore, as well as Dubai Block Capital. WEBDAO is committed to using blockchain technology to promote the development and application of digital currency and provide users around the world with safer, faster and more convenient digital currency services.
  Pitaya adheres to the user-centered principle and aims to provide users with technological innovation, faster and more efficient block contract technology services. Pitaya will soon launch a series of innovative digital currency products, including digital wallets, digital currency trading platforms, digital asset management platforms, smart public chains, etc. to meet the different needs of users.
  In the future, Pitaya will continue to delve into the field of blockchain innovation technology to bring users a better digital ecological service experience.`,
  about1:'Welcome to join the PITAYA autonomous community! ',
  about2:'PITA issuance rules',
  about3:'• Issuing public chain: BSC',
  about4:'•Full name of token: Pitaya',
  about5:'•Token symbol: PITA',
  about6:'• Total circulation: 21 million',
  about7:'• Trading fee: 3.6%, detailed distribution is as follows:',
  about8:'* 1.2% converted to BNB to increase the burning reward amount (burning contract)',
  about9:'* 0.8% converted to BNB for weight dividend burning address (burning weight dividend contract)',
  about10:'* 0.8% reward for inviting players who buy and sell user addresses (addresses holding less than 100 PITA coins cannot enjoy the reward)',
  about11:'1--30%; 2--20%; 3--15%; 4~10--5% ',
  about12:'* 0.8% converted to BNB for ecological construction'
  },
  menuList: ['Home','Community Learning Download', 'Burning','./file/PITA-E.pdf'],
  burn: {
  ybp: 'burning data',
  xx: 'My Community',
  gz: 'PITA Ecological Contract Rules',
  gogaldata1: 'Total amount of PITA burned in the entire network',
  gogaldata2: 'Total amount of BNB burning rewards in the entire network',
  gogaldata3: 'Total amount of PITA community rewards in the entire network',
  gogaldata4: 'Total amount of BNB rewards received across the entire network',
  first: 'My total PD burn',
  jl: 'Current reward',
  ls: 'History of receiving rewards',
  lqjl: 'Receive rewards',
  jls: 'My PITA invitation reward',
  lj: 'My invitation reward',
  yq: 'My number of direct referrals',
  url: 'Inviter\'s address',
  rs: 'burn',
  rsdown: 'Burn PITA to obtain PD TOKEN of corresponding value',
  rsyq: 'Invite friends to participate in burning to unlock more benefits! ',
  copy: 'Copy invitation link',
  rsc: 'Total value of BNB currently available for preemption and burning',
  maxrs: 'The total amount of PITA currently available for preemption and burning',
  td: 'Community number',
  address: 'address',
  pd: 'Burning PD',
  tdpd: 'Community burning PD',
  mypita:'My PITA Balance',
  p1: 'PITA1.0 ecological contract mechanism',
 
 
  p2: 'How to bind recommendation relationship? ',
  p3: 'Recommended method to bind the relationship between addresses: transfer 1, 10, 100, 1000 or 10000PITA to the other party\'s wallet address (the address that receives the PITA transfer for the first time) to bind permanently. ',
  p4: 'PITA buying and selling transaction method',
 
  p5: 'BUY--Buy PITA:',
  p6: 'Transfer BNB to PITA contract address',
  p7: '0xA0690A4D2DcF321A9dfa5343d86B2c92BCC2F3f8 ',
  p8: 'You can obtain the corresponding amount of PITA according to the current currency price. ',
 
  p9: 'SELL--Sell PITA:',
  p10:'Transfer PITA to PITA contract address',
  p11:'0xA0690A4D2DcF321A9dfa5343d86B2c92BCC2F3f8',
  p12:'You can obtain BNB of corresponding value according to the current currency price. ',
 
  p13: 'A special reminder: please use the correct asset transfer! ',
 
  p14: 'How to actively participate in burning PITA:',
  p15: 'Burning quota: The BNB value quota that can be burned across the entire network comes from the 1.2% exchange fee, the BNB exchanged for documentary sales, and community ecological construction airdrops. These three major aspects will increase the amount of burnable rewards for players. Users can add the burning contract to the observation wallet to instantly view the current BNB value burning amount of PITA. ',
  p16:'Participate in burning:',
  p17: 'Transfer PITA to burning contract address',
  p18:'0x8eC46dD2F013d1D383D14Cb5543fF8D6f597d2D1',
  p19:' Instantly obtain the corresponding value of BNB (recovery of capital) and the corresponding amount of dividend weight. Holding weights can permanently enjoy transaction fee dividends (weighted dividends. The burning amount of a single snap purchase cannot exceed 50% of the current holdings. The same address can participate repeatedly, with unlimited restrictions. The maximum number of dividend weights enjoyed by each address is 2 Weights.',
  p20: 'Burning reward: 65% of the PITA burned by the player is immediately burned to the black hole address, and 35% is used for the team',
  p21:'Invitation rewards: 1-10%, 2-5%, 3 to 6 -3%, 7 to 10-2%. ',
  p22: 'Special note: Addresses holding less than 100 PITA coins cannot enjoy community rewards. ',
 
  p23:'The pot automatically burns:',
  p24: '0.15% burn per hour is embedded in the pot tokens, and a total of 3.6% is burned per day. The burn function is embedded in the tokens and will be automatically destroyed forever. The pot automatically burns and destroys PITA every day - the PITA in the pot continues to burn and deflate, and the price of PITA automatically rises every day. ',
  p25: 'Following selling contract mechanism - increasing burning rewards and accelerating deflation PITA',
  p26: 'The working logic of the follow-up selling contract: Whenever an address buys PITA, the follow-up selling contract will be triggered to automatically sell 30% of the purchase amount of PITA and immediately transfer the converted BNB to the burning contract to increase the burning reward. Quota. ',
 
  }
 }