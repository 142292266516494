// i18n文件夹下新建index.js
import Vue from 'vue'
import Vuei18n from 'vue-i18n'
Vue.use(Vuei18n )
import zhCN from './locales/zh-CN'
import enUS from './locales/en_US'
import ko from './locales/ko'
import jp from './locales/jp'

const i18n = new Vuei18n({
  locale: sessionStorage.getItem('language') || 'zh-cn', // 默认显示语言
  messages: {  // 文案
    'zh-cn': zhCN,
    'en-us': enUS,
    'jp':jp,
    'ko':ko
  }
})

export default i18n


