export default {
  home: {
    conn: '首頁',
    about:'關於火龍果',
    aboutinfo:`Pitaya誕生於瑞士加密穀，由WEBDAO創建。
    WEBDAO是一群來自瑞士、美國和新加坡的專業區塊鏈生態應用研發技術團隊以及迪拜區塊資本組成的應用研發聯盟。WEBDAO致力於利用區塊鏈技術推動數字貨幣的發展和應用，為世界各地的用戶提供更加安全、快捷、便利的數字貨幣服務。
    Pitaya堅持以用戶為中心的原則，旨在為用戶提供技術創新，更快捷，更高效的區塊合約技術服務。Pitaya後續即將推出一系列創新的數字貨幣產品，包括數字錢包、數字貨幣交易平臺、數字資產管理平臺、智能公鏈等以滿足用戶不同的需求。
    未來，Pitaya將繼續深耕區塊鏈創新技術領域，為用戶帶來更好的數字生態服務體驗。`,
    about1:'歡迎加入PITAYA自治社群！',
    about2:'PITA發行規則',
    about3:'• 發行公鏈：BSC',
    about4:'• 代幣全稱：Pitaya',
    about5:'• 代幣符號：PITA',
    about6:'• 發行總量：2100萬枚',
    about7:'• 買賣手續費：3.6%，詳細分配如下：',
    about8:'* 1.2%轉換為BNB增加燃燒獎勵額度（燃燒合約）',
    about9:'* 0.8%轉換為BNB用於燃燒地址的權重分紅（燃燒權重分紅合約）',
    about10:'* 0.8%獎勵該買賣用戶地址的邀請玩家（持幣數量少於100枚PITA的地址不可以享受獎勵）',
    about11:'1--30%；2--20%；3--15%；4~10--5% ',
    about12:'* 0.8%轉換為BNB用於生態建設',
  },
  //menuList: ['首頁', '社区学习下载','搶佔燃燒','./file/PITA-C.pdf'],
  menuList: ['首頁', '搶佔燃燒'],
  
  burn: {
    ybp: '燃燒數據',
    xx: '我的社區',
    gz: 'PITA生態合約规则',
    gogaldata1: '全網PITA燃燒總量',
    gogaldata2: '全網BNB燃燒獎勵總量',
    gogaldata3: '全網PITA社群獎勵總量',
    gogaldata4: '全網BNB獎勵領取總量',
    first: '我的PD燃燒總量',
    jl: '當前獎勵',
    ls: '歷史領取獎勵',
    lqjl: '領取獎勵',
    jls: '我的PITA邀請獎勵',
    lj: '我的邀請獎勵',
    yq: '我的直推數量',
    url: '邀請人地址',
    rs: '燃燒',
    rsdown:'燃燒PITA可以獲得相應價值的PD TOKEN',
    rsyq: '邀請好友參與燃燒解鎖更多權益！ ',
    copy: '複製邀請链接',
    rsc: '當前可搶佔燃燒BNB總價值',
    maxrs: '當前可搶佔燃燒PITA總量',
    td: '社區人數',
    address: '地址',
    pd: '燃燒PD',
    tdpd: '社區燃燒PD',
    mypita:'我的PITA余额',

    p1: 'PITA1.0生態合約機制',


    p2: '如何綁定推薦關係？',
    p3: '綁定地址之間推薦關係的方法：轉賬1、10、100、1000或10000PITA至對方錢包地址（首次接收PITA轉賬的地址）即可永久綁定。 ',
    p4: 'PITA買賣交易方法',

    p5: 'BUY--買入PITA：',
    p6: '轉賬BNB至PITA合約地址 ',
    p7: '0xA0690A4D2DcF321A9dfa5343d86B2c92BCC2F3f8 ',
    p8: '即可按照當前幣價獲得相應數量的PITA。',

    p9: 'SELL--賣出PITA：',
    p10:'轉賬PITA至PITA合約地址',
    p11:'0xA0690A4D2DcF321A9dfa5343d86B2c92BCC2F3f8',
    p12:'即可按照當前幣價獲得相應價值的BNB。',

    p13:'一特別提醒：敬请使用正确资产转账！',

    p14:'主動參與燃燒PITA 的方法：',
    p15:'燃燒額度：全網可燃燒的BNB價值額度來自1.2%的交易所手續費、跟單賣出兌換的BNB及社區生態建設空投。3大方面都會增加玩家的可燃燒獎勵額度。用戶可以添加燃燒合約至觀察錢包，即時查看當前PITA的BNB價值燃燒額度。',
    p16:'參與燃燒：',
    p17:'轉賬PITA至燃燒合約地址',
    p18:'0x8eC46dD2F013d1D383D14Cb5543fF8D6f597d2D1',
    p19:'即時獲得相應價值的BNB（回本）及相應數量的分紅權重。持有權重永久可享受交易手續費分潤（加權分紅。單次搶購燃燒額度不可超過當前持有量的50%   同一地址可重複參與，無數次限制。每個地址享受的分紅權重上限數量為2權重。',
    p20:'燃燒獎勵：玩家燃燒的PITA，65%即時燃燒至黑洞地址，35%用於團隊',
    p21:'邀請獎勵：1-10%，2-5%，3至6 -3% ,7至10-2% 。',
    p22:'特別備註：持幣數量少於100枚PITA的地址不可享受社區獎勵。',

    p23:'底池自動燃燒：',
    p24:'底池代幣中嵌入每小時0.15%的銷毀，每天總計燃燒3.6%銷毀功能嵌入代幣中，將永遠自動銷毀。底池每天自動燃燒銷毀PITA--底池PITA不斷燃燒通縮，PITA價格每天自動上漲。',
    p25:'跟單賣出合約機制--增加燃燒獎勵   加速通縮PITA',
    p26:'跟單賣出合約工作邏輯：每當有地址買入PITA的時候，會觸發跟賣合約自動賣出該筆買單量30%的PITA即時將兌換得到的BNB轉賬至燃燒合約增加燃燒獎勵額度。',

  }
}
